import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export interface HeadertextWithUnderlineProps {
  /** Text to display */
  text: string;
  /** Text color */
  textColor?: string;
}

export const HeadertextWithUnderline: PimoReactComponent<
  HeadertextWithUnderlineProps
> = ({ text, textColor }) => {
  return (
    <Box
      data-testid="HeaderWithLine"
      sx={{
        padding: "10px 10px",
        marginTop: "10px",
        position: "relative",
        width: "100%",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: textColor ?? "#122B54",
          fontWeight: 600,
        }}
      >
        {text}
      </Typography>
      <Divider
        sx={{
          borderColor: textColor ?? "#122B54",
          marginTop: "5px",
        }}
      />
    </Box>
  );
};

export default HeadertextWithUnderline;
