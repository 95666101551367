import { Cache } from "@pimo/pimo-app-builder";
import { App, Link } from "isrm-home-portal-types";

import { STRAPI_URL } from "../env";

const cache = new Cache();

export async function fetchApps(): Promise<App[]> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/bff-app`, {});

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as App[];

    return json;
  } catch {
    return [];
  }
}
export async function fetchLinks(): Promise<Link[]> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/bff-link`, {});

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Link[];

    return json;
  } catch {
    return [];
  }
}
