import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { useState } from "react";
import Markdown from "react-markdown";

export type InfoTitleCardProps = {
  title: string;
};

export const InfoTitleCard: PimoReactComponent<
  InfoTitleCardProps,
  FileList | null
> = ({ title }) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  const INFO_TEXT = `
**Welcome to Our IS Resource Page**

We aim to make your life easier, which is why we've created this page with all the important links you need for your work in the IS field.

Here, you'll find links for various IS modules, contact persons, and additional information, such as whether a Connect page is available.

Additionally, we've included the most important links for your daily work, such as Archer, Degreed, and more.`;

  return (
    <TitleCard
      title={title}
      endSlot={
        <Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
          <IconButton
            onClick={(e) => setPopoverAnchor(e.target as HTMLButtonElement)}
          >
            <InfoOutlined />
          </IconButton>
          <Popover
            open={!!popoverAnchor}
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => setPopoverAnchor(null)}
          >
            <Typography component="div" sx={{ maxWidth: "500px", px: 2 }}>
              <Markdown>{INFO_TEXT}</Markdown>
            </Typography>
          </Popover>
        </Box>
      }
    />
  );
};
