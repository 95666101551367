import { Logout } from "@mui/icons-material";
import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
} from "@pimo/pimo-components";
import { Profile } from "isrm-home-portal-types";

import { APP_KEY } from "../app-key";
import { STRAPI_URL } from "./env";
import { HomePlugin, HomePluginState } from "./pages/home-plugin";
import { theme } from "./theme";

export type IsrmHomePortalAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  UniversalAppState &
  HomePluginState;

const homePlugin = new HomePlugin<IsrmHomePortalAppState>();
const loginPlugin = new LoginPlugin<Profile, IsrmHomePortalAppState>(
  "/",
  STRAPI_URL,
  "Information Security Modules"
);

// App
const app = App.create<IsrmHomePortalAppState>(
  composeInitialState<IsrmHomePortalAppState>({
    ...initialDefaultOverlayState,
    apps: [],
    links: [],
    isLoading: false,
    isSidebarOpen: false,
    isLoggedIn: false,
  }),
  APP_KEY
);
app.setTheme(theme);

// Overlay
const overlayView = app.createOverlayView({ name: "Overlay" });

const overlay = overlayView.addComponent({
  component: DefaultOverlay,
});

overlay.mapState(({ isSidebarOpen, userProfile }) => ({
  menuEntries: [
    {
      title: "Home",
      link: "/",
      level: 0,
    },
  ],
  header: {
    username: `${userProfile?.name ?? "n/a"} (${userProfile?.email ?? "n/a"})`,
    hideMenuButton: true,
    logo: { big: "allianz.svg", small: "allianz-small.svg" },
    entries: [
      {
        text: "Logout",
        onClick: () => {
          loginPlugin.logout();
        },
        icon: Logout,
      },
    ],
  },
  isSidebarOpen,
}));

overlay.on("overlay:toggle-sidebar", () => {
  app.setAppState({
    ...app.getAppState(),
    isSidebarOpen: !app.getAppState().isSidebarOpen,
  });
});

app.registerPlugin(homePlugin);
app.registerPlugin(loginPlugin);

const RenderedApp = app.render();

export default RenderedApp;
