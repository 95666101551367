import {
  App,
  DeriveTableProps,
  Plugin,
  Table,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  GridLayout,
  HeadertextWithUnderline,
  LinkIconCell,
  TextCardCell,
} from "@pimo/pimo-components";
import { App as ISRMApp, Link } from "isrm-home-portal-types";

import { InfoTitleCard } from "../../component/info-title-card";
import { AppInfoCards } from "../components/app-info-cards";
import { APP_ROUTES } from "../constants";
import { STRAPI_URL } from "../env";
import { fetchApps, fetchLinks } from "../helpers/fetch-helpers";

export interface HomePluginState {
  apps: ISRMApp[];
  links: Link[];
}
export class HomePlugin<AppState extends UniversalAppState & HomePluginState>
  implements Plugin<AppState, HomePluginState>
{
  onRegister(app: App<AppState>): void {
    const view = app.createView({
      name: "Home",
      layout: new GridLayout(),
    });

    const headline = view.addComponent({
      component: InfoTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    headline.mapState(() => ({
      title: "Information Security Modules",
    }));

    const apps = view.addComponent({
      component: AppInfoCards,
      layoutProps: { xs: 12 },
    });
    apps.mapState(({ apps = [] }) => ({
      apps: apps
        .sort((app1, app2) => app1.order - app2.order)
        .map((app) => ({
          name: app.name,
          description: app.description,
          logo: app.logo?.data?.attributes.url
            ? `${STRAPI_URL}${app.logo?.data.attributes.url}`
            : undefined,
          url: app.url ?? undefined,
          email: app.email ?? undefined,
          emailSubject: `Information Security Modules - ${app.name}`,
          infoText: app.infoText,
          tableEntries:
            app.tableEntries?.map((entry) => ({
              ...entry,
              url: entry.url ?? undefined,
              email: entry.email ?? undefined,
            })) ?? [],
          module: app.module,
        })),
    }));

    const otherResourcesHeader = view.addComponent({
      component: HeadertextWithUnderline,
      layoutProps: {
        xs: 12,
      },
    });

    otherResourcesHeader.mapState(() => ({
      text: "Other Resources",
    }));

    otherResourcesHeader.mapVisibility(({ links = [] }) => !!links.length);

    const linkTable = new Table(
      [
        { component: TextCardCell },
        { component: TextCardCell },
        { component: LinkIconCell },
      ],
      "report"
    );

    const linkTableComponent = view.addComponent<
      DeriveTableProps<typeof linkTable>,
      unknown,
      unknown
    >({
      component: linkTable,
      layoutProps: {
        xs: 12,
      },
    });

    linkTableComponent.mapState(({ links = [] }) => ({
      tableHeaderEntries: ["Name", "Description", "Link"],
      data:
        links
          .sort((a, b) => a.order - b.order)
          .map((entry) => ({
            columnProps: [
              {
                header: "",
                body: entry.name,
              },
              {
                header: "",
                body: entry.description,
              },
              {
                url: entry.url,
                linkText: entry.url,
              },
            ],
            rowProps: {},
          })) ?? [],
    }));

    linkTableComponent.mapVisibility(({ links = [] }) => !!links.length);

    const route = app.createRoute({
      path: APP_ROUTES.home,
      view,
    });

    route.on("load", async () => {
      const [apps, links] = await Promise.all([fetchApps(), fetchLinks()]);

      app.setAppState({
        ...app.getAppState(),
        apps,
        links,
      });
    });
  }
}
