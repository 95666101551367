import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";

import { StyledLink } from "../../pimo-components";

export interface LinkIconCellProps {
  /** URL to navigate to when the link is clicked. */
  url: string;
  /** Custom text to display for the link (default is "Link"). */
  linkText?: string;
}

export const LinkIconCell: FC<LinkIconCellProps> = ({
  url,
  linkText = "Link",
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        overflow: "hidden",
      }}
    >
      <StyledLink href={url} target="_blank">
        <Typography
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: theme.palette.primary.main,
            textDecoration: "none",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {linkText}
        </Typography>
      </StyledLink>
    </Box>
  );
};

export default LinkIconCell;
