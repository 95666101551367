import { Grid } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  AppInfoCard,
  AppInfoCardProps,
  HeadertextWithUnderline,
} from "@pimo/pimo-components";

interface AppInfoCardsProps {
  apps: (AppInfoCardProps & { module: { name: string; order?: number } })[];
}

export const AppInfoCards: PimoReactComponent<AppInfoCardsProps> = ({
  apps = [],
}) => {
  const groupedApps = apps.reduce(
    (acc, app) => {
      const moduleName = app.module?.name ?? "Uncategorized modules";
      const moduleOrder = app.module?.order ?? Infinity;
      if (!acc[moduleName]) {
        acc[moduleName] = { apps: [], order: moduleOrder };
      }
      acc[moduleName].apps.push(app);
      return acc;
    },
    {} as Record<string, { apps: AppInfoCardProps[]; order: number }>
  );

  const sortedModules = Object.entries(groupedApps)
    .sort(([, moduleA], [, moduleB]) => moduleA.order - moduleB.order)
    .map(([moduleName, { apps: appsInModule }]) => ({
      moduleName,
      apps: appsInModule,
    }));

  return (
    <Grid container spacing={2}>
      {sortedModules.map(({ moduleName, apps }) => (
        <Grid item xs={12} key={moduleName}>
          <HeadertextWithUnderline text={moduleName} />
          <Grid container spacing={2}>
            {apps.map((app) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={app.name}>
                <AppInfoCard {...app} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
